.custom-input {
    width: 296px;
    padding: 0px 16px;
    height: 44px;
    align-items: flex-start;
    color: var(--light-basic-primary, #242731);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border-radius: 6px;
    border: 1px solid var(--light-basic-strokes, #D4D4D4);
    outline: none;
  }

  

 